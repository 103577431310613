<template>
  <v-theme-provider dark>
    <section id="hero-alt">
      <v-img
        :height="$vuetify.breakpoint.mdAndUp ? 600 : 960"
        :gradient="gradient"
        :lazy-src="lazySrc"
        :src="src"
        color="#45516b"
        flat
        max-width="100%"
        tile
      >
        <v-container class="fill-height px-4 py-12">
          <v-responsive
            class="d-flex align-center"
            height="100%"
            width="100%"
          >
            <base-heading
              :title="$t('HeroAlt.title')"
            />

            <!-- for mobile if its bigger than md-->
            <base-body class="d-none d-md-block">
              <v-row
                class="ma-3"
                justify="center"
                align="center"
                no-gutters
              >
                <v-col
                  cols="5"
                  sm="5"
                  class="ma-4 pa-3 text-left"
                >
                  {{ $t('HeroAlt.text') }}
                </v-col>

                <v-col
                  cols="5"
                  sm="5"
                  class="ma-4 pa-3 text-left"
                >
                  <div
                    v-for="(option, i) in $t('HeroAlt.projects')"
                    :key="i"
                    class="light-blue--text"
                  >
                    <span> {{ option.title }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </base-body>

            <!-- for mobile if its smaller than md-->
            <base-body class="d-md-none">
              <v-row
                class="ma-3 text-left"
              >
                {{ $t('HeroAlt.text') }}
              </v-row>
              <v-row
                class="ma-3 text-left"
                no-gutters
              >
                <div
                  v-for="(option, i) in $t('HeroAlt.projects')"
                  :key="i"
                  class="light-blue--text"
                >
                  <span> {{ option.title }}
                  </span>
                </div>
              </v-row>
            </base-body>
          </v-responsive>
        </v-container>
      </v-img>
    </section>
  </v-theme-provider>
</template>

<script>
  // Components
  import {
    HexToRGBA,
    RGBAtoCSS,
  } from 'vuetify/lib/util/colorUtils'

  export default {
    name: 'SectionHeroAlt',

    metaInfo () {
      return {
        changed: meta => (this.title = meta.titleChunk.toUpperCase()),
      }
    },

    provide: {
      heading: { align: 'center' },
    },

    computed: {
      gradient () {
        const color = `${this.$vuetify.theme.themes.light.secondary}CC`
        const overlay = RGBAtoCSS(HexToRGBA(color))

        return `to top, ${overlay}, ${overlay}`
      },
      src () {
        return this.$route.meta.src
      },
      lazySrc () {
        return this.$route.meta.lazySrc
      },
      items () {
        return [
          { text: 'HOME', to: '/' },
          { text: this.title },
        ]
      },
    },
  }
</script>
